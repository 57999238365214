import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  fonts: {
    heading: "'Noto Sans', sans-serif",
    body: "'Noto Sans', sans-serif",
  },
  colors: {
    ahuaBlue: {
      0: "#E6F2FA",
      5: "#D2E9F9",
      10: "#BCDFF8",
      20: "#82C5F4",
      30: "#55AEED",
      40: "#2F95E5",
      50: "#137ED9",
      60: "#0068CA",
      70: "#0058B7",
      80: "#0047A0",
      90: "#003686",
      100: "#00276B",
    },
    ahuaBrown: {
      0: "#F5EBE6",
      5: "#F0DED4",
      10: "#EBD8CE",
      20: "#D9C2B5",
      30: "#BFA18F",
      40: "#9B7862",
      50: "#7A563D",
      60: "#66452E",
      70: "#5C391F",
      80: "#523121",
      90: "#472A14",
      100: "#381F0B",
    },
    ahuaOrange: {
      0: "#FAEFE6",
      5: "#FAE3D2",
      10: "#FCD6BB",
      20: "#FFCAA3",
      30: "#FAA86E",
      40: "#F58433",
      50: "#E86B13",
      60: "#E05D00",
      70: "#D15100",
      80: "#C24300",
      90: "#A32F00",
      100: "#7A1A00",
    },
    ahuaGreen: {
      0: "#E8F7DA",
      5: "#DBF7C6",
      10: "#CCF0AD",
      20: "#ACEB7C",
      30: "#93D65E",
      40: "#75C738",
      50: "#51B816",
      60: "#46A314",
      70: "#359900",
      80: "#2C8F00",
      90: "#207500",
      100: "#114700",
    },
    ahuaYellow: {
      0: "#FAF4DB",
      5: "#FAF1CC",
      10: "#FAECB8",
      20: "#FAE285",
      30: "#FADA5C",
      40: "#F5CC29",
      50: "#F0C20A",
      60: "#E0B400",
      70: "#D6A800",
      80: "#C79200",
      90: "#AD7600",
      100: "#7A4E00",
    },

    africaConnect: {
      primary: "#928222",
    },
  },
})

export default theme
